body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #000000;
  text-align: left;
  background-color: #fff;
  height: 100vh;
  max-height: -webkit-fill-available;
}